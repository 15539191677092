import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';

// API
import { ApiCore } from '../../config/api';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private url: string = ApiCore.BASE_URL ;
  private defaultHeaders = new HttpHeaders()
    .append("Content-Type", "application/json")
    .append("Prefer", "count=exact")
    .append("Prefer", "resolution=merge-duplicates");

  constructor(private httpClient: HttpClient) {}

  /**
   * Get a list of objects/data from the API
   * @param endpoint Object/data name
   * @param queryParams  GET parameters to be included in the query
   */
  public list(endpoint: string, queryParams?: any): Observable<any> {
    let queryParamsString = '';
    if (queryParams) {
      queryParamsString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');
      return this.httpClient.get(`${this.url}${endpoint}?${queryParamsString}`, {headers: this.defaultHeaders});
    }else{
      return this.httpClient.get(`${this.url}${endpoint}`, {headers: this.defaultHeaders});
    }
    
  }

  /**
   * Get single object/data from API
   * @param endpoint Object/data name
   * @param id Object/data identifier
   */
  public get(endpoint: string, id: string): Observable<any> {
    return this.httpClient.get(`${this.url}${endpoint}`)
  }
 
  /**
   * Get single object/data from API
   * @param endpoint Object/data name
   * @param id Object/data identifier
   */
  public getById(endpoint: string, id: string): Observable<any> {
    return this.httpClient.get(`${this.url}${endpoint}/${id}`)
  }

  /**
   * Create an object/data using API
   * @param endpoint Object/data name
   * @param object Object/data as a JSON {key:value}
   */
  public create(endpoint: string, object: any): Observable<any> {
    return this.httpClient.post(`${this.url}${endpoint}`, object)
  }

  /**
   * Update an object/data using API
   * @param endpoint Object/data name
   * @param id Object/data identifier
   * @param object Object/data as a JSON {key:value}
   */
  public update(endpoint: string, id: string, object: any): Observable<any> {
    return this.httpClient.put(`${this.url}${endpoint}/${id}`, object);
  }

  /**
   * Update an object/data using API
   * @param endpoint Object/data name
   * @param object Object/data as a JSON {key:value}
   */
  public updateDirect(endpoint: string,  object: any): Observable<any> {
    return this.httpClient.put(`${this.url}${endpoint}`, object)
  }

   /**
   * Update an object/data using API
   * @param endpoint Object/data name
   * @param object Object/data as a JSON {key:value}
   */
  public updateDirectPost(endpoint: string,  object: any): Observable<any> {
    return this.httpClient.post(`${this.url}${endpoint}`, object)
  }

  /**
   * Delete an object/data from API
   * @param endpoint Object/data name
   * @param id Object/data identifier
   */
  public delete(endpoint: string, id: string): Observable<any> {
    return this.httpClient.delete(`${this.url}/${endpoint}/${id}`);
  }


  public GetOperatorConfig(): Observable<any> {
    return this.httpClient.get(`${this.url}operator/operator-config`)
  }

  /**
   * Catch the error and get the data from error property
   * @param error HttpErrorResponse object
   * @returns JSON object with the error property value
   */
  private errorHandler(error: HttpErrorResponse) {
    return throwError(() => new Error(error.error))
  }

  // Returns an observable
  public upload(endpoint: string, file: any):Observable<any> {

    // Create form data
    const formData = new FormData();
    // Store form name as "file" with file data
    formData.append("file", file, file.name);

    // Make http post request over api
    // with formData as req
    return this.httpClient.post(`${this.url}${endpoint}`, formData);
  }

    // Returns an observable
  public uploadImageProfile(endpoint: string, file: any):Observable<any> {

      // Create form data
    const formData = new FormData();
      // Store form name as "file" with file data
    formData.append("file", file, file.name);

      // Make http post request over api
      // with formData as req
    return this.httpClient.put(`${this.url}${endpoint}`, formData);
  }

  public uploadOperatorLogo(endpoint: string, data: any):Observable<any> {
    const formData = new FormData();
    formData.append("file", data.file, data.file.name);
    formData.append("id", data.id)
    return this.httpClient.put(`${this.url}${endpoint}`, formData);
  }

      /**
   * Get single object/data from API
   * @param endpoint Object/data name
   * @param id Object/data identifier
   */
  public getGameURL(endpoint: string, id: string): Observable<any> {
    return this.httpClient.get(`${this.url}/${endpoint}/${id}`)
  }

  public updateGameLogo(endpoint: string, id:string, file: any){
    const formData = new FormData();
    formData.append("file", file, file.name);
    return this.httpClient.put(`${this.url}${endpoint}/${id}`, formData);
  }

  public getAggregatorWebConfig(): Observable<any>{
    return this.httpClient.get<any>(`${this.url}aggregator/aggregator-web-config`);
  }
  public getOffset(): Observable<number>{
    return this.getAggregatorWebConfig().pipe(
      map(res => {
        const utcOffsetString = res.data?.timeZoneCat?.description;
        // const match = utcOffsetString.match(/UTC([+-]\d+):(\d+)/);
        const newMatch = utcOffsetString.match(/UTC[\W]\d{2}:\d{2}/gi);
        if(!newMatch) throw new Error('Invalid utcOffset')
        const result = newMatch[0].slice(3, newMatch[0].length); //extraemos 3 del string match para quitar "UTC"
        const number = Number(result.split(':').join('.')); // Quitamos los dos puntos y unimos el resultado
        //Evaluamos si no es numero es porque se encontró con el 000
        if (isNaN(number)) {
          return 0;
        }
        return number;
      }),
      catchError(error => {
        console.error('Error fetching Aggregator config info:', error);
        throw new Error('Error fetching Aggregator config info')
      })
    );
  }
  get userOffsetHours(){
    return this.getOffset().pipe(
      map((offsetValue) => {
        console.log({offsetValue});
        return offsetValue;
      }),
      catchError((error) => {
        console.error('Error:', error);
        throw new Error('userOffsetHours ERROR')
      })
    );
  }
}
