<div class="layout-menu-wrapper shadow-3" [style]="{ transform: showMenu? 'translateX(0)': '' }" [ngClass]="{'layout-sidebar-active': appMain.sidebarActive}" style="border-right-style: solid; border-right-width: 2px; border-right-color: #303440; background-color: #303440; "
     (click)="appMain.onSidebarClick($event)" (mouseover)="appMain.onSidebarMouseOver($event)" (mouseleave)="appMain.onSidebarMouseLeave($event)">
    <div class="menu-logo" *ngIf="!appMain.isHorizontal() || appMain.isMobile()" style="background-color: #303440;">
        <a class="app-name mb-4" *ngIf="!appMain.isSlim() || appMain.isMobile()">
            <div *ngIf="showLoadSpin" style="width: 200px; height: 80px;" class="flex justify-content-center align-items-center animation-duration-300">
                <p-progressSpinner styleClass="h-3rem custom-spinner" strokeWidth="4"></p-progressSpinner>
            </div>
            <div class="mb-2" style="max-width: 200px; max-height: 60px; overflow: hidden;">
                <p-image [imageStyle]="{minHeight: '60px', minWidth: '200px',maxHeight: '60px', maxWidth: '200px'}" *ngIf="!showLoadSpin"  imageClass="pl-0 w-10 h-full border-round-sm fadein animation-duration-300"  [src]="appLogo ? appLogo : 'assets/images/no-photo-available.png'" alt="Image" [preview]="false"></p-image>
            </div>
        </a>
        <a class="menu-pin" (click)="appMain.onToggleMenu($event)" [style]="{ display: appMain.isMobile() ? 'flex': '', alignItems: appMain.isMobile() ? 'center': '', justifyContent: appMain.isMobile() ? 'center': ''}">
            <span (click)="showMenu = false" *ngIf="appMain.isMobile()" class="pi pi-times"></span>
            <span *ngIf="!appMain.isMobile() && appMain.isSidebar() && !appMain.sidebarStatic && appMain.pinActive" class="pi pi-unlock"></span>
            <span *ngIf="!appMain.isMobile() && appMain.isSidebar() && appMain.sidebarStatic && appMain.pinActive" class="pi pi-lock"></span>
        </a>
    </div>

    <div class="layout-menu-container">
        <ul class="layout-menu" style="background-color: #303440;">
            <ng-container *ngFor="let item of model; let i = index;">
                <li app-menuitem [item]="item" [index]="i" [root]="true" ></li>
                
            </ng-container>
            
        </ul>
    </div>
</div>

<p-button 
    (click)="showMenu = !showMenu"  
    icon="pi pi-bars"
    styleClass="p-button-text p-button-secondary"
    [style]="{position: 'absolute', top: '12px', left: '24px'}"
    ></p-button>

<style>
     :host ::ng-deep .custom-spinner .p-progress-spinner-circle {
        animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
    }
    
    @keyframes custom-progress-spinner-color {
        100%,
        0% {
            stroke: #85838D;
        }
        40% {
            stroke: #85838D;
        }
        66% {
            stroke: #85838D;
        }
        80%,
        90% {
            stroke: #85838D;
        }
    }
    
    @keyframes custom-progress-spinner-dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }
</style>
